<template>
	<div class="h-full">
		<fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange"
			@onPageChange="onPageChange" :queryParam="queryParam">
			<div class="h-handle-button" slot="buttonGroup">
				<span>
					<el-button type="primary" size="small" @click="issue" v-right-code="'Storeprocessinfo:Issue'">下发</el-button>
				</span>
			</div>
			<!--           <el-row slot="reserve1" class="h-table-choose" v-if="activeName==1">
                <el-button type="text" @click="showlist(-1)" v-bind:class="storeProcessStatus==-1? 'activebtn':''">
                    全部({{SearchCountData.TotalCount}})
                </el-button>
                <el-button type="text" @click="showlist(100)" v-bind:class="storeProcessStatus==100? 'activebtn':''">
                    待下发({{SearchCountData.StayDownCount}})
                </el-button>
                <el-button type="text" @click="showlist(200)" v-bind:class="storeProcessStatus==200? 'activebtn':''">
                    已下发({{SearchCountData.AlreadyDownCount}})
                </el-button>
                <el-button type="text" @click="showlist(210)" v-bind:class="storeProcessStatus==210? 'activebtn':''">
                    已加工({{SearchCountData.DisposeCount}})
                </el-button>
                <el-button type="text" @click="showlist(300)" v-bind:class="storeProcessStatus==300? 'activebtn':''">
                    已完成({{SearchCountData.FinishCount}})
                </el-button>
            </el-row> -->
			<p slot="elList">
				<el-table ref="storeprocessListTable" :data="dataSource.Result" border
					@selection-change="handleSelectionChange" highlight-current-row>
					<el-table-column type="selection" width="55" v-if="!config.isDetailDisplay"></el-table-column>
					<el-table-column v-for="(col,index,count)  in dataSource.ColDefs.BodyFieldParams" :key="index"
						:prop="col.FieldName" :label="col.DisplayName" :render-header="bindFilter(queryParam,col)"
						:fixed="index==0&&(!config||!config.isDetailDisplay)"
						:min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
						v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<2))">
						<template slot-scope="scope">
							<span v-if="col.FieldName==='ProcessOrderCode'"><span type="text"
									@click="rowClick(scope.row)"
									style="text-decoration:underline;color:#1874CD;cursor:pointer">{{ scope.row[col.FieldName] }}</span></span>
							<span v-else> {{ scope.row[col.FieldName] }}</span>
						</template>
					</el-table-column>
					<!--<el-table-column label="操作" :min-width="80" v-if="!config.isDetailDisplay">
                        <template slot-scope="scope">
                            <el-button @click="setWarehouseProjectStatus(scope.row)" v-if="scope.row.WarehouseProjectStatus==100" v-right-code="'Manage_Right_Commerce_WarehouseProject_StartEndWarehouseProject'" size="small" type="text">停用</el-button>
                            <el-button @click="setWarehouseProjectStatus(scope.row)" v-else v-right-code="'Manage_Right_Commerce_WarehouseProject_StartEndWarehouseProject'" size="small" type="text">启用</el-button>
                        </template>
                    </el-table-column>-->
				</el-table>
			</p>
		</fixed-list>
	</div>
</template>
<script>
	export default {
		mounted() {
			this.Utils.lazy(() => {
				var _this = this;
				this.Event.$on("reloadProjectList", () => this.reloadPageList());
				this.Event.$on("onAddProject", () => this.addProject());
			});
			this.initialize();
		},
		data() {
			return {
				multipleSelection: [],
				queryParam: {
					PageIndex: 1,
					PageSize: 10,
					TabItems: [],
				},
				//storeProcessStatus: -1,
				dataSource: {
					ColDefs: {},
					Result: [],
					TotalCount: 0
				},
				activeName: "1",
				SearchCountData: {
					TotalCount: '',
					StayDownCount: '',
					AlreadyDownCount: '',
					DisposeCount: '',
					FinishCount: ''
				}
			}
		},
		props: {
			config: {
				isDetailDisplay: false,
				isButtonlDisplay: false,
			},
			option: {},
		},
		methods: {
			searchProject: function() {
				this.onPageChange(this.queryParam);
			},
			reloadPageList: function() {
				this.initialize();
			},
			showlist: function(index) {
			//	this.storeProcessStatus = index;
				this.queryParam.pageIndex = 1; //切换订单状态时 初始化值
				this.initialize();
			},
			initialize() {
				this.onPageChange(this.queryParam);
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
				this.$emit("onCheckRow", this.multipleSelection);
			},
			onPageChange(param) {
				var _this = this;
				var queryParam = this.Utils.extend({}, this.queryParam);
				/* if (queryParam.params.StoreProcessStatus == undefined) {
					queryParam.params.StoreProcessStatus = {
						fieldName: "StoreProcessStatus",
						fieldType: 3,
						fieldValue: ""
					};
				}
				if (_this.storeProcessStatus != -1) {
					queryParam.params.StoreProcessStatus.fieldValue = [_this.storeProcessStatus];
				} */
				this.$ajax.query("omsapi/storeprocessinfo/getlist", "post", queryParam, data => {
					_this.dataSource = data;
					_this.queryParam.TabItems = result.TabItems;
				});
			/* 	if (_this.storeProcessStatus != -1) {
					delete queryParam.params.StoreProcessStatus;
				} */
				/* this.$ajax.query("omsapi/storeprocess/getcount", "post", queryParam, (data) => {
					_this.SearchCountData = data.Result;
				}); */
			},
			onDataSourceChange(ds) {
				var _this = this;
				_this.dataSource = {
					ColDefs: {
						BodyFieldParams: []
					},
					Result: [],
					TotalCount: 0
				};
				_this.$nextTick(function() {
					_this.dataSource = ds;

				});
			},
			rowClick(row) {
				var result = {};
				this.$ajax.send("omsapi/storeprocessinfo/getstoreprocessdetailbyid", "get", {
					id: row.StoreID
				}, (data) => {
					this.Event.$emit("clearEditProjectForm");
					result = data.Result;
				});

				//商品明细分页-初始化
				var search = {
					PageIndex: 1,
					PageSize: 10,
					IsNoPage: false,
					PageParams: {
						ProcessOrderCode: row.ProcessOrderCode
					},
				};
			 	this.$ajax.query("omsapi/storeprocessinfo/getstorematerialitemlist", "post", search, (data) => {
					result.StoreMaterialItem = {};
					result.StoreMaterialItem.Result = data.Result;
					result.StoreMaterialItem.TotalCount = data.TotalCount;
					result.StoreMaterialItem.PageIndex = 1;
					result.StoreMaterialItem.PageSize = 10;
				}, null, false);
				//发货记录分页-初始化
				this.$ajax.query("omsapi/storeprocessinfo/getstoreproductitemlist", "post", search, (data) => {
					result.StoreProductItem = {};
					result.StoreProductItem.Result = data.Result;
					result.StoreProductItem.TotalCount = data.TotalCount;
					result.StoreProductItem.PageIndex = 1;
					result.StoreProductItem.PageSize = 10;
				}, null, false); 
				this.onChangeEditDataSource(result);
			},
			issue: function(command) {
				var _this = this;
				if(_this.multipleSelection==null||_this.multipleSelection.length<=0){
					
						_this.Utils.messageBox("请选择操作数据", "error");
						return;
				}
				var processOrderCodeList = _this.Utils.selectionsToArr(_this.multipleSelection, "ProcessOrderCode");
				_this.$ajax.send("omsapi/storeprocessinfo/issue", "post", processOrderCodeList, (result) => {
					_this.Utils.messageBox(result.OperationDesc || "操作成功", "success");
					this.initialize();
				}, null, true);
			},
			onChangeEditDataSource(data) {
				this.config.isDetailDisplay = true;
				this.$emit("onChangeEditDataSource", data);
			},
		},
	}
</script>
<style>
</style>